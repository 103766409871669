import { Button, FormControl, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Spinner, Table } from "@cev/design-components";
import type { AuthContext } from "@eduthings/app-scripts/models/security/AuthContext";
import { DistrictSwitcherUIViewModel } from "@eduthings/app-scripts/viewModels/layout/DistrictSwitcherUIViewModel";
import { observer } from "mobx-react";
import { useMemo } from "react";

interface DistrictSwitcherUIProps {
    authContext: AuthContext;
}

export function render(props: DistrictSwitcherUIProps) {
    const viewModel = useMemo(() => {
        const vm = new DistrictSwitcherUIViewModel();
        vm.districtId = props.authContext?.state?.districtId ?? 0;
        return vm;
    }, [props.authContext?.state?.districtId]);

    return (
        <>
            {viewModel.districtName && (
                <Button
                    onClick={async () => {
                        await viewModel.openSwitchDistrictModal();
                    }}
                >
                    {viewModel.districtName}
                </Button>
            )}
            <Modal
                show={viewModel.isVisible}
                onHide={() => {
                    viewModel.cancelSwitchDistrict();
                }}
            >
                <ModalHeader closeButton={!viewModel.isProcessingDistrictSwitch}>
                    <ModalTitle>Switch District</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    {/* search/filter */}
                    {!viewModel.isLoadingDistricts && (
                        <FormControl
                            type="text"
                            placeholder="Enter district name..."
                            className="mb-2"
                            disabled={viewModel.isProcessingDistrictSwitch}
                            onChange={(e) => {
                                viewModel.filterByDistrictName(e.target.value);
                            }}
                        ></FormControl>
                    )}

                    {/* list districts */}
                    <Table>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Name</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {viewModel.filteredDistricts.map((district) => (
                                <tr key={district.districtId} className={viewModel.isLoadingDistricts ? "skeleton-loader skeleton-loader-visible" : ""}>
                                    <td className="skeleton-loader-item">{district.districtId}</td>
                                    <td className="skeleton-loader-item">{district.name}</td>
                                    <td className="skeleton-loader-item">
                                        <Button
                                            variant="primary"
                                            href={`/district_switcher/${district.districtId}/?return_to=${window.location.href}`}
                                            className={viewModel.isProcessingDistrictSwitch ? "disabled" : ""}
                                            onClick={() => {
                                                viewModel.isProcessingDistrictSwitch = true;
                                            }}
                                        >
                                            Switch
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    {viewModel.isProcessingDistrictSwitch && (
                        <div>
                            <Spinner size="sm" /> <span>Switching Districts...</span>
                        </div>
                    )}
                    {viewModel.isLoadingDistricts && (
                        <div>
                            <Spinner size="sm" /> <span>Loading Districts...</span>
                        </div>
                    )}
                </ModalFooter>
            </Modal>
        </>
    );
}

export default observer(render);
