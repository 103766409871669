import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "@cev/design-components";
import type { AuthContext } from "@eduthings/app-scripts/models/security/AuthContext";
import { DistrictSwitcherViewModel } from "@eduthings/app-scripts/viewModels/layout/DistrictSwitcherViewModel";
import { observer } from "mobx-react";
import { useMemo } from "react";

interface DistrictSwitcherProps {
    authContext: AuthContext;
}

export function render(props: DistrictSwitcherProps) {
    const viewModel = useMemo(() => {
        const vm = new DistrictSwitcherViewModel();
        vm.districtId = props.authContext?.state?.districtId ?? 0;
        return vm;
    }, [props.authContext?.state?.districtId]);

    return (
        <Modal show={viewModel.isVisible}>
            <ModalHeader>
                <ModalTitle>District Changed</ModalTitle>
            </ModalHeader>
            <ModalBody>{`You switched to district ${viewModel.newDistrictName} in another window, but this page had loaded district ${viewModel.districtName}.  Would you like to switch to ${viewModel.newDistrictName} or reload the current district ${viewModel.districtName}?`}</ModalBody>
            <ModalFooter>
                <Button variant="link" href={`/district_switcher/${viewModel.districtId}/?return_to=${window.location.href}`}>
                    Reload
                </Button>
                <Button variant="primary" href={`/district_switcher/${viewModel.newDistrictId}/?return_to=${window.location.href}`}>
                    Switch
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default observer(render);
